@mixin HEXtoRGBA($property, $color, $opacity: 1) {
  #{$property}: rgba($color, $opacity);
}

@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);

  @if $type == string {
    @if $min == xs {
      @media (max-width: 767px) {
        @content;
      }
    } @else if $min == sm {
      @media (min-width: 768px) {
        @content;
      }
    } @else if $min == md {
      @media (min-width: 992px) {
        @content;
      }
    } @else if $min == lg {
      @media (min-width: 1200px) {
        @content;
      }
    } @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
  } @else if $type == number {
    $query: "all" !default;
    @if $min != 0 and $max != 0 {
      $query: "(min-width: #{$min}) and (max-width: #{$max})";
    } @else if $min != 0 and $max == 0 {
      $query: "(min-width: #{$min})";
    } @else if $min == 0 and $max != 0 {
      $query: "(max-width: #{$max})";
    }
    @media #{$query} {
      @content;
    }
  }
}

@mixin orientation($direction) {
  $orientation-landscape: "(orientation:landscape)";
  $orientation-portrait: "(orientation:portrait)";

  @if $direction == landscape {
    @media #{$orientation-landscape} {
      @content;
    }
  }
  @if $direction == portrait {
    @media #{$orientation-portrait} {
      @content;
    }
  }
}
