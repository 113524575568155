.metaverse
{
    .content
    {
        width: 100%;
        height: 189px;
        border-radius: 0.25rem;
        box-sizing: border-box;
        background-color: #d9d9d9;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .backDrop {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.3);
            z-index: 0;
        }

        .textContainer
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;

            p {
                font-family: var(--var-inter);
                font-size: 1rem;
                font-weight: 600;
            }
        }
    }
}
