@import "../../../scss/mixins";
@import "../../../scss/variables";

.metaverseCard {
  display: flex;
  padding: 0.75rem;
  gap: 1rem;
  border-radius: 0.375rem;
  border: 0.0625rem solid var(--tab-border-color);
  background: var(--metaverse-card-background-color);
  margin-bottom: 1rem;
  text-decoration: none;
  max-height: 111px;
  box-sizing: border-box;

  .imgContainer {
    border-radius: 0.1875rem;
    background-color: #d9d9d9;
    width: 81px;
    height: 85px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .greyImage {
    img {
        filter: grayscale(100%);
      }
  }

  .content {
    flex: 1;
    h3 {
      margin-bottom: 0.5rem;
      color: var(--header-text-color);
      font-size: 1rem;
      font-family: var(--var-inter);
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-align: initial;
    }

    p {
      display: flex;
      align-items: center;
      color: var(--first-message-text-color);
      font-family: var(--var-inter);
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 1rem;

      .dot {
        display: inline-block;
        width: 0.25rem;
        height: 0.25rem;
        border-radius: 50%;
        background-color: #afafaf;
        margin: 0 0.5rem;
      }

      &.peopleRegistered {
        gap: 0.5rem;
        color: var(--text-color);
        font-family: var(--var-inter);
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0;

        svg {
          * {
            stroke: var(--primary-color);
          }
        }
      }
    }
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  @include breakpoint(0, $smScreen) {
    @include orientation(landscape) {
      margin-bottom: 0;
      padding: 0.75rem 0.625rem;
      gap: 0.75rem;

      .content {
        h3 {
          margin-bottom: 0.4rem;
          font-size: 1rem;
        }

        p {
          font-size: 0.625rem;
          margin-bottom: 0.75rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-align: initial;

          .dot {
            display: inline-block;
            width: 0.2rem;
            height: 0.2rem;
            margin: 0 0.4rem;
          }

          &.peopleRegistered {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            font-size: 0.75rem;
          }
        }
      }
    }
  }

  @include breakpoint(0, 550px) {
    @include orientation(landscape) {
      .content {
        h3 {
          font-size: 1rem;
        }

        p {
          font-size: 0.875rem;
          margin-bottom: 1rem;

          .dot {
            width: 0.25rem;
            height: 0.25rem;
            margin: 0 0.5rem;
          }

          &.peopleRegistered {
            gap: 0.5rem;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
