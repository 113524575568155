@import "../../scss/mixins";
@import "../../scss/variables";

.base
{
    right: 57px;
    bottom: 34px;
    position: absolute;
    min-height: 90vh; // The reason here is to override the height on edge (Issue submitted by Abdelfattah Karam (One of our clients) on Jan 31, 2024 and solved by Mohamed Ghazi (FE team leader) on Feb 01, 2024);
    height: 90vh;

    transition: all 0.3s ease-in-out;

    > div {
        height: 100%;
    }

    &.withoutMessage {
        height: 460px;
        min-height: 460px;
    }

    @include breakpoint(0, $smScreen)
    {
        @include orientation(landscape)
        {
            right: 0;
            bottom: 0;
            height: 100dvh;

            &.withoutMessage {
                height: 100dvh;
            }

            &.baseSm {
                right: 22px;
                bottom: 32px;
            }
        }
    }

    @media (max-width: 667px)
    {
        right: 0;
        bottom: 0;
        height: 100dvh;

        &.withoutMessage {
            height: 100dvh;
        }

        &.baseSm {
            right: 22px;
            bottom: 32px;
        }
    }
}
