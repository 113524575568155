@import "../../../scss/mixins";
@import "../../../scss/variables";

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(0, $smScreen) {
    > svg {
      width: 80%;
      height: 80%;
    }
  }
}
