.footer {
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-top: 0.0625rem solid var(--main-border-color);
  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-decoration: none;
    padding: 0.88rem 1rem;
    border: none;
    border-radius: 0.625rem;
    outline: none;
    cursor: pointer;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 0.9375rem;
    font-family: var(--var-inter);
    font-weight: 600;

    &.outlined {
      background-color: transparent;
      color: #8c8c8c;
      font-weight: 400;
    }
  }
  .calling {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    font-family: var(--var-inter);
    font-weight: 600;
    color: var(--text-color);
  }

  @media (max-width: 424px) {
    a,
    button {
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
